import { graphql, navigate } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { LayoutContext } from '../components/organisms/layoutProvider';
import PostPrev from '../components/atoms/postPrev';
import { media } from '../utils/media-queries';
import SEO from '../components/organisms/seo';
import Fade from '../components/atoms/fade';
import Float from '../components/atoms/float';

const BlogPage = ({ data }) => {
  const { language, theme, toggleTheme } = useContext(LayoutContext);

  useEffect(() => {
    navigate(language === 'en' ? '/blog' : '/de/blog', { replace: true });
  }, [language]);

  const { title, posts } = data.blog.nodes[0];
  const { illustration5 } = data.overlay.nodes[0];

  return (
    <>
      <SEO siteTitle="Blog" />
      <StyledBlog className="spacing-inner">
        <p className="title">{title[language] || title.en}</p>
        {posts
          .slice(0)
          .reverse()
          .map((post, i) => (
            <PostPrev
              key={i}
              slug={
                language === 'en'
                  ? `blog/${post.slug.current}`
                  : `de/blog/${post.slug.current}`
              }
              src={post.previewImage.image.asset.fluid}
              alt={post.previewImage.alt}
              title={post.title[language] || post.title.en}
              year={
                post.publishedAt &&
                (post.publishedAt[language] || post.publishedAt.en)
              }
            />
          ))}
      </StyledBlog>
      {theme === 'light' && (
        <StyledOverlays onClick={toggleTheme} className="overlay">
          <Fade className="overlay4" active={theme === 'light'}>
            <Float speed={200} duration={15}>
              <Img
                fluid={illustration5.image.asset.fluid}
                alt={illustration5.alt}
              />
            </Float>
          </Fade>
        </StyledOverlays>
      )}
    </>
  );
};

const StyledBlog = styled.section`
  min-height: 100vh;
  margin-top: var(--v-spacing-S);

  .title {
    font-family: var(--font-1);
    padding-bottom: var(--v-spacing-M);
  }

  @media ${media.M} {
    .title {
      padding-bottom: var(--v-spacing-S);
    }
  }
`;

const StyledOverlays = styled.div`
  .overlay4 {
    width: 50%;
    transform: translate3d(50vw, -15vw, 0);
  }
`;

export const query = graphql`
  query Blog {
    blog: allSanityBlogPage {
      nodes {
        title {
          de
          en
        }
        posts {
          slug {
            current
          }
          title {
            de
            en
          }
          publishedAt {
            de
            en
          }
          previewImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    overlay: allSanityOverlay {
      nodes {
        illustration5 {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

BlogPage.propTypes = {
  data: PropTypes.object,
};

export default BlogPage;
